var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import PaymentsTable from "./PaymentsTable.vue";
export default Vue.extend({
    components: {
        PaymentsTable: PaymentsTable,
    },
    apollo: {
        viewerAgent: {
            fetchPolicy: "cache-and-network",
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query CommissionQuery($paymentsAmount: Int) {\n          viewerAgent {\n            id\n            totalCommissionPaid\n            totalCommissionDue\n            ...PaymentsTable_agent\n          }\n        }\n        ", "\n      "], ["\n        query CommissionQuery($paymentsAmount: Int) {\n          viewerAgent {\n            id\n            totalCommissionPaid\n            totalCommissionDue\n            ...PaymentsTable_agent\n          }\n        }\n        ", "\n      "])), PaymentsTable.options.fragments.agent),
            variables: { paymentsAmount: undefined },
        },
    },
    metaInfo: function () {
        return {
            title: this.$t("Commission").toString(),
        };
    },
});
var templateObject_1;
