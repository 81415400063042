var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
export default Vue.extend({
    props: {
        agent: {
            type: Object,
            required: true,
        },
    },
    fragments: {
        agent: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment PaymentsTable_agent on Agent {\n        payments(amount: $paymentsAmount) {\n          id\n          amount\n          referenceNumber\n          datePaid\n        }\n      }\n    "], ["\n      fragment PaymentsTable_agent on Agent {\n        payments(amount: $paymentsAmount) {\n          id\n          amount\n          referenceNumber\n          datePaid\n        }\n      }\n    "]))),
    },
});
var templateObject_1;
